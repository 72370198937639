import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL;
const Room_Servie_Base_URL =
  process.env.REACT_APP_API_URL + "/view/roomservice";

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST ROOM--SERVICE API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostRoomServiceApi = createAsyncThunk(
  "PostRoomServiceApi",
  async ({ Token, values }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(`${Base_url}/roomservice`, values, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
        setTimeout(() => {
          window.location.pathname = "room-service";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM--SERVICE API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetRoomServiceApi = createAsyncThunk(
  "GetRoomServiceApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(Room_Servie_Base_URL, { headers });
      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM--SERVICE API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetRoomServiceByIdApi = createAsyncThunk(
  "GetRoomServiceByIdApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(`${Base_url}/view/roomservice/${id}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% PUT ROOM--SERVICE API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PutRoomServiceApi = createAsyncThunk(
  "PutRoomServiceApi",
  async ({ id, Token, values }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.put(
        `${Base_url}/edit/roomservice/${id}`,
        values,
        {
          headers,
        }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);

        setTimeout(() => {
          window.location.pathname = "room-service";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% DELETE ROOM--SERVICE API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const DeleteRoomServiceApi = createAsyncThunk(
  "DeleteRoomServiceApi",
  async ({Token,id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
   const response = await axios.delete(`${Base_url}/delete/roomservice/${id}`,{headers});
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% DELETE ROOM-SERVICE-ITEM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const DeleteRoomServiceItemApi = createAsyncThunk(
  "DeleteRoomServiceItemApi",
  async ({ id, Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = axios.delete(
        `${Base_url}/roomservice/items/delete/${id}`,
        { headers }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

const RoomServiceSlice = createSlice({
  name: "RoomServiceSlice",
  initialState: {
    iserror: null,
    status: null,
    message: "",
    isLoading: false,
    RoomServiceData: [],
    SingleRoomServiceData: {},
  },

  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%% POST API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(PostRoomServiceApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostRoomServiceApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostRoomServiceApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.RoomServiceData = action.payload.result;
    });

    //%%%%%%%%%%%%%%%%%% GET API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(GetRoomServiceApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRoomServiceApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRoomServiceApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.RoomServiceData = action.payload.status
        ? action.payload.result
        : [];
    });

    //%%%%%%%%%%%%%%%%%% GET SINGLE API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(GetRoomServiceByIdApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRoomServiceByIdApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRoomServiceByIdApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.SingleRoomServiceData = action.payload.status
        ? action.payload.result
        : {};
    });

    //%%%%%%%%%%%%%%%%%% UPDATE API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(PutRoomServiceApi.rejected, (state, action) => {
      state.isLoading = true;
      state.message = action.payload.message;
    });
    builder.addCase(PutRoomServiceApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutRoomServiceApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%% DELETE API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(DeleteRoomServiceApi.rejected, (state, action) => {
      state.isLoading = true;
      state.message = action.payload.message;
    });
    builder.addCase(DeleteRoomServiceApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteRoomServiceApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%% DELETE SERVICE ITEM API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(DeleteRoomServiceItemApi.rejected, (state, action) => {
      state.isLoading = true;
      // state.message=action.payload.message
    });
    builder.addCase(DeleteRoomServiceItemApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteRoomServiceItemApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });
  },
});

export default RoomServiceSlice.reducer;
