import React from "react";
import "./Loader.css";
import { Box } from "@mui/material";

const Loader = () => {
  return (
    <Box
      textAlign={"center"}
      display={"flex"}
      alignContent={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
    >
      <div className="loader">
        <span className="hour"></span>
        <span className="min"></span>
        <span className="circel"></span>
      </div>
    </Box>
  );
};

export default Loader;
