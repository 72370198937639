import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL + "/category";

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST CATEGORY API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostCategoryApi = createAsyncThunk(
  "PostCategoryApi",
  async ({ values, Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(Base_url, values, { headers });

      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST BULK CATEGORY API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostBulkCategoryApi = createAsyncThunk(
  "PostBulkCategoryApi",
  async ({ formdata, Token }) => {
    const headers = {
    "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(`${Base_url}/byfile`, formdata, { headers });

      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetCategoryApi = createAsyncThunk(
  "GetCategoryApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(Base_url, { headers });

      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% PUT ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PutCategoryApi = createAsyncThunk(
  "PutCategoryApi",
  async ({ Token, values, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    const jsondata = JSON.stringify(values);

    try {
      const response = await axios.put(`${Base_url}/edit/${id}`, jsondata, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% DELETE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const DeleteCategoryApi = createAsyncThunk(
  "DeleteCategoryApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.delete(`${Base_url}/delete/${id}`, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data ;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% SINGLE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const SingleCategoryApi = createAsyncThunk(
//   "SingleCategoryApi",
//   async ({ Token, values, id }) => {
//     const headers = {
//       "Content-type": "application/json",
//       Authorization: `Bearer ${Token}`,
//     };
//     const jsondata = JSON.stringify(values);
//     try {
//       const response = await axios.get(`${Base_url}/${id}`, jsondata, {
//         headers,
//       });
//       return response.data;
//     } catch (error) {
//       console.error('error', error)
//       if (error.response.status === 500) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

const FoodCategorySlice = createSlice({
  name: "FoodCategorySlice",
  initialState: {
    isLoading: false,
    status: null,
    message: "",
    FoodCategoryData: [],
  },
  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%%%%%% POST API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostCategoryApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostCategoryApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostCategoryApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% POST BULK API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostBulkCategoryApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostBulkCategoryApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostBulkCategoryApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetCategoryApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetCategoryApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetCategoryApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.FoodCategoryData = action.payload.status
        ? action.payload.result
        : [];
    });

    //%%%%%%%%%%%%%%%%%%%%%%% DELETE API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(DeleteCategoryApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteCategoryApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteCategoryApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% PUT API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PutCategoryApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutCategoryApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutCategoryApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
    });
  },
});

export default FoodCategorySlice.reducer;
