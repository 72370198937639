import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const BaseUrl = process.env.REACT_APP_API_URL + "/view/allguests";
const DeleteUrl = process.env.REACT_APP_API_URL + "/delete/guest";
const AddUrl = process.env.REACT_APP_API_URL + "/add/new/guest";
const AddBulk_Url = process.env.REACT_APP_API_URL + "/addguest/byfile";
const singleUrl = process.env.REACT_APP_API_URL + "/view/specefic/guest";
const Edit_Base_URL = process.env.REACT_APP_API_URL + "/edit/guestdetails";
const UPDATE_ADMIN_PROFILE_URL =
  process.env.REACT_APP_API_URL + "/update/profile";

//%%%%%%%%%% POST USER API %%%%%%%%%%%%
export const PostGuestData = createAsyncThunk(
  "PostGuestData",
  async ({ Token, formdata }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(AddUrl, formdata, { headers });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);

        setTimeout(() => {
          window.location.pathname = "/guest";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      ErrorAlert(error.response.data.message);
      return error.response.data;
    }
  }
);

//%%%%%%%%%% POST BULK USER API %%%%%%%%%%%%
export const PostBulkGuestData = createAsyncThunk(
  "PostBulkGuestData",
  async ({ Token, formdata }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(AddBulk_Url, formdata, { headers });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      ErrorAlert(error.response.data.message);
      return error.response.data;
    }
  }
);

//%%%%%%%%%% GET USER API %%%%%%%%%%%%
export const GetAllGuestData = createAsyncThunk(
  "GetAllGuestData",
  async ({ Token }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.get(BaseUrl, { headers });
      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%% GET Single USER API %%%%%%%%%%%%
export const GetSingleGuestData = createAsyncThunk(
  "GetSingleGuestData",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.get(`${singleUrl}/${id}`, { headers });
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%% PUT USER API %%%%%%%%%%%%
export const PutGuestData = createAsyncThunk(
  "PutGuestData",
  async ({ Token, formdata, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.put(`${Edit_Base_URL}/${id}`, formdata, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
        setTimeout(() => {
          window.location.pathname = "/guest";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%% DELETE USER API %%%%%%%%%%%%
// export const DeletGuestData = createAsyncThunk(
//   "DeletGuestData",
//   async ({ Token, id }) => {
//     const headers = {
//       "Content-type": "multipart/form-data",
//       Authorization: `Bearer ${Token}`,
//     };
//     try {
//       const response = await axios.delete(`${DeleteUrl}/${id}`, { headers });
//       if (response.data.status === true) {
//         SuccessAlert(response.data.message);
//       } else {
//         ErrorAlert(response.data.message);
//       }
//       return response.data;
//     } catch (error) {
//       console.log('error', error)
//       if (error.response.status === 500) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

//%%%%%%%%%% PUT USER API %%%%%%%%%%%%
export const EditAdminProfileApi = createAsyncThunk(
  "EditAdminProfileApi",
  async ({ Token, formdata, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.put(
        `${UPDATE_ADMIN_PROFILE_URL}`,
        formdata,
        { headers }
      );
      if (response.data.status === true) {
        Cookies.set("image", response.data.result.image, { expires: 2 });
        Cookies.set("checkin", response.data.result.default_checkin, {
          expires: 2,
        });
        Cookies.set("checkout", response.data.result.default_checkout, {
          expires: 2,
        });
        Cookies.set("username", response.data.result.fullname, {
          expires: 2,
        });
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

const GuestDataTableSlice = createSlice({
  name: "GuestDataTableSlice",
  initialState: {
    GuestDatatableData: [],
    SpecificGuestdata: {},
    iserror: null,
    isLoading: null,
    message: "",
    status: null,
  },

  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%% ADD API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostGuestData.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(PostGuestData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostGuestData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      // state.GuestDatatableData = action.payload.result;
    });

    //%%%%%%%%%%%%%%%%%%% ADD BULK API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostBulkGuestData.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(PostBulkGuestData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostBulkGuestData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      // state.GuestDatatableData = action.payload.result;
    });

    // //%%%%%%%%%%%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetAllGuestData.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(GetAllGuestData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetAllGuestData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.GuestDatatableData = action.payload.status
        ? action.payload.result
        : [];
    });

    // //%%%%%%%%%%%%%%%%%%% Admin Profile  HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(EditAdminProfileApi.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(EditAdminProfileApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(EditAdminProfileApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      // state.GuestDatatableData = action.payload.status?action.payload.result:[];
    });

    // //%%%%%%%%%%%%%%%%%%% VIEW Single API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetSingleGuestData.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(GetSingleGuestData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetSingleGuestData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.SpecificGuestdata = action.payload.status
        ? action.payload.result
        : {};
    });

    ////%%%%%%%%%%%%%%%%%%% UPDATE API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PutGuestData.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(PutGuestData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutGuestData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
    });

    // //%%%%%%%%%%%%%%%%%%% DELETE API HANDLE %%%%%%%%%%%%%%%%%%%%%
    // builder.addCase(DeletGuestData.rejected, (state, action) => {
    //   state.iserror = "Client side Error";
    // });
    // builder.addCase(DeletGuestData.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(DeletGuestData.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.status = action.payload.status;
    //   state.message = action.payload.message;
    // });
  },
});

export default GuestDataTableSlice.reducer;
