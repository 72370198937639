import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL + "/view/specefic/guest";
// //%%%%%%%%%%%%%%%%%%%%%%%%%%%% EDIT API REQUEST %%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const PutProfille = createAsyncThunk(
//   "PutProfille",
//   async ({ Token, values, id }) => {
//     const headers = {
//       "Content-type": "multipart/form-data",
//       Authorization: `Bearer ${Token}`,
//     };
//     try {
//       const response = await axios.put(`${Base_url}/${id}`, values, {
//         headers,
//       });
//       if (response.data.status === true) {
//         SuccessAlert(response.data.message);
//       } else {
//         ErrorAlert(response.data.message);
//       }
//       return response.data;
//     } catch (error) {
//       if (error.response.status === 500) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET API REQUEST %%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetApiAdmin = createAsyncThunk(
  "GetApiAdmin",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(`${Base_url}/${id}`, { headers });
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

const AdminSlice = createSlice({
  name: "AdminSlice",
  initialState: {
    isLoading: false,
    message: "",
    iserror: null,
    AdminData: {},
  },

  extraReducers: (builder) => {
    // // %%%%%%%%%% EDIT API HANDLE %%%%%%%%%%%%%%%5
    // builder.addCase(PutProfille.rejected, (state, actio) => {
    //   state.isLoading = true;
    //   state.iserror = "Something Is Error";
    // });
    // builder.addCase(PutProfille.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(PutProfille.fulfilled, (state, action) => {
    //   state.message = action.payload.message;
    //   state.AdminData = state.AdminData.map((item, index) =>
    //     item.id === action.payload.result.id ? action.payload.result : item
    //   );
    // });

    // %%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%5
    builder.addCase(GetApiAdmin.rejected, (state, actio) => {
      state.isLoading = true;
      state.iserror = "Error in Client";
    });
    builder.addCase(GetApiAdmin.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetApiAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.AdminData = action.payload.result;
    });
  },
});

export default AdminSlice.reducer;
