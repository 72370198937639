import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Stack,
  ListItem,
  ListItemText,
  List,
  Divider,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImage from "../../../Assets/img/room-placeholder.webp";
import "./GuestCard.css";
import dayjs from "dayjs";

const GuestCard = ({ data, handleImgOpen }) => {
  const [translationValuesHouse, setTranslationValuesHouse] = useState([]);

  const setActiveImageSliderHome = (cardIndex, imageIndex) => {
    const newTranslationValues = [...translationValuesHouse];
    newTranslationValues[cardIndex] = `-${imageIndex * 100}%`;
    setTranslationValuesHouse(newTranslationValues);
  };

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (data?.document_images) {
      let imageArray = (data?.document_images).split(",");
      setImages(imageArray);
    }
  }, [data?.document_images]);

  return (
    <>
      {/* <Box
       sx={{
         height: "auto",
         overflow: "hidden",
         border: "1px solid rgba(12, 0, 0, 0.1)",
         boxShadow: "0px 20px 09px rgba(12, 0, 0, 0.1)",
       }}
     > */}
      <Grid
        item
        lg={9}
        // md={handlegridrow ? 12 : 4}
        // sm={handlegridrow ? 12 : 6}
        xs={9}
        sx={{
          mb: 2,
        }}
      >
        <Paper
          sx={{
            border: "1px solid rgba(12, 0, 0, 0.1)",
            borderRadius: "16px",
          }}
        >
          <Box sx={{ position: "relative" }}>
            {images && images.length > 0 ? (
              <div className="stacked-image">
                {images.map((image, imageIndex) => (
                  <div
                    key={imageIndex}
                    className="item-image"
                    style={{
                      height: "250px",
                      transform: `translateX(${translationValuesHouse[0]})`,
                    }}
                  >
                    <LazyLoadImage
                      key={imageIndex}
                      loading="lazy"
                      onClick={() =>
                        handleImgOpen(
                          `${process.env.REACT_APP_API_URL}/uploads/guests/${image}`
                        )
                      }
                      src={
                        image
                          ? `${process.env.REACT_APP_API_URL}/uploads/guests/${image}`
                          : placeholderImage
                      }
                      className="item-image"
                      effect="blur"
                      height={"100%"}
                      width={"100%"}
                      style={{
                        cursor:"pointer"
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="stacked-image">
                <div
                  className="item-image"
                  style={{
                    height: "250px",
                  }}
                >
                  <LazyLoadImage
                    src={placeholderImage}
                    loading="lazy"
                    className="item-image"
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              </div>
            )}
          </Box>
          <div className="sliderbtn">
            <ul>
              {images &&
                images.length > 0 &&
                images.map((btn, btnIndex) => (
                  <li key={btnIndex}>
                    <div
                      className="slider-btn"
                      onClick={() => setActiveImageSliderHome(0, btnIndex)}
                    ></div>
                  </li>
                ))}
            </ul>
          </div>
          <Box sx={{ p: 2, mt: -3, pb: 0 }}>
            <Stack direction={"row"} sx={{ mb: 1, mt: "15px" }}>
              <Typography
                variant="subtitle"
                sx={{
                  fontFamily: "'roboto',sans-serif",
                  color: "#EB554A",
                  mt: -2,
                  mb: 1,
                  fontSize: "18px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="subtitle"
                  sx={{
                    fontFamily: "'roboto',sans-serif",
                    color: "#EB554A",
                    fontSize: "22px",
                    width: "100%",
                  }}
                >
                  Name :{" "}
                </Typography>
                {String(data?.fullname).toUpperCase()}
              </Typography>
              <Typography
                variant="subtitle"
                sx={{
                  fontFamily: "'roboto',sans-serif",
                  color: "#EB554A",
                  mt: -2,
                  mb: 1,
                  fontSize: "18px",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                <Typography
                  variant="subtitle"
                  sx={{
                    fontFamily: "'roboto',sans-serif",
                    color: "#EB554A",
                    fontSize: "22px",
                    width: "100%",
                  }}
                >
                  Mobile :{" "}
                </Typography>
                {String(data?.phone_number).toUpperCase()}
              </Typography>
            </Stack>
          </Box>
          <List
            sx={{
              maxHeight: "200px", // Adjust the height as needed
              overflow: "auto",
              pl: 2,
              "::-webkit-scrollbar": {
                width: "0px",
              },
            }}
          >
            <Grid container spacing={2} sx={{ width: "100%" }}>
              {data &&
                Object.entries(data).map(([key, value], index) => {
                  if (
                    !["document_images", "fullname", "phone_number"].includes(
                      key
                    )
                  ) {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <Paper
                          sx={{
                            // padding: "10px",
                            // marginBottom: "10px",
                            border: "1px solid rgba(12, 0, 0, 0.1)",
                            boxShadow: "0px 20px 09px rgba(12, 0, 0, 0.1)",
                          }}
                        >
                          <ListItem disablePadding>
                            <Grid container xs={12}>
                              <Grid item xs={12} sx={{ pl: 1 }}>
                                <ListItemText
                                  primary={key ? key : ""}
                                  secondary={
                                    value
                                      ? key === "created_at"
                                        ? dayjs(value).format(
                                            "DD/MM/YYYY hh:mm A"
                                          )
                                        : value
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Paper>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </List>
        </Paper>
      </Grid>
      <Divider />
      {/* </Box> */}
    </>
  );
};

export default GuestCard;
